// Helper function
const getRouteName = () => {
	const path = window.location.pathname;

	if (path.length === 0 || path.length === 1) return 'home';
	else if (path.includes('career')) return 'career';
	else return false;
}


class JobBoard {
	constructor(board, offersLimit, mode = 'home') {
		this.apiURL = 'https://vayio.recruitee.com/api';
		this.board = board;
		this.offers = [];
		this.activeOffer = null;
		this.activeCategory = {};
		this.offersLimit = offersLimit;
		this.mode = mode;
		this.categories = {};
		this.offersLength = 0;

		this.fetchJobs();
	}

	checkURL() {
		const windowURL = new URL(window.location.href);
		const params = new URLSearchParams(windowURL.search);
		const offerSlug = params.get('offer');

		if (offerSlug && offerSlug.length > 0) {
			const offer = this.offers.find((offer) => offer.slug === offerSlug);
			if (offer) this.showOffer(offer.id, offer);
		}
	}

	async fetchJobs() {
		try {
			const response = await fetch(`${this.apiURL}/offers/`);
			if (response.status !== 200 || !response.ok) throw new Error('Error');
			const data = await response.json();

			this.offers = data.offers;
			this.offersLength = data.offers.length;
			this.renderOffers(this.offers, 0, this.offersLimit);
			this.checkURL();

			// if (this.mode === 'career') this.createCategories();
		} catch (err) {
			this.handleAPIError();
			console.log(err);
		}
	}

	handleAPIError(errorMessage = 'Currently there are no offers') {
		const markup = `
      <div class="container">
        <h4 class="job-board__error">
          ${errorMessage}
        </h3>
      </div>
    `;

		this.board.innerHTML = markup;
		if (this.mode === 'career') this.removeLoadMore();
	}

	createCategories() {
		let allDepartmentCategories = [];
		let allLocationCategories = [];
		const filterButton = document.querySelector('#filter');

		this.offers.forEach((offer) => {
			allDepartmentCategories.push(offer.department);
			allLocationCategories.push(offer.location);
		});

		this.categories = {
			location: [...new Set(allLocationCategories)],
			department: [...new Set(allDepartmentCategories)],
		}

		filterButton.addEventListener('click', () => {
			this.createCategoriesModal();
		});
	}

	createCategoriesModal() {
		const modal = this.createModalNode(['job-modal'], [{
			name: 'data-modal-offer-id',
			value: 'categories',
		}]);

		const modalMarkup = `
      <div class="job-modal__inner">
        <button class="bt-close job-modal__mobile-close"></button>
      
        <header class="job-modal__header">
          <button class="job-modal__back">
            <div class="bt-arrow job-modal__back-icon"></div>
            <span class="job-modal__back-text">
              Back 
            </span>
          </button>

          <h2 class="job-modal__header-title">
            Filter by categories
          </h2>
        </header>

        <div class="job-modal__content row">
					${Object.keys(this.categories)
						.map(
							categoryKey =>
								`
								<div class="job-modal-category col-md-6">
									<h4 class="job-modal-category__name">
										${categoryKey === 'city' ? 'Location' : categoryKey}
									</h3>

									<ul class="job-modal-category__list">
										${this.categories[categoryKey]
											.map(
												category =>
													`
														<li class="job-modal-category__item">
															<button 
																class="job-modal-category__button"
																data-category-name="${categoryKey}"
																data-category-value="${category}"
															>
																${category}
															</button>	
														</li>
													`
											).join('')}
									</ul>
								</div>
							`
						).join('')}
				</div>
      </div>
    `;

		modal.innerHTML = modalMarkup		;
		this.openModal(modal);
		this.handleModalNativeEvents(modal, '.job-modal__back', '.job-modal__mobile-close');
		const categoriesButtons = document.querySelectorAll('.job-modal__inner .job-modal-category__button');
		
		categoriesButtons.forEach((categoryButton) => {
			const { categoryValue, categoryName } = categoryButton.dataset;

			categoryButton.addEventListener('click', () => {
				this.filterCategories(categoryName, categoryValue);
				categoryButton.classList.add('job-modal-category__button--active');
			});

			if (categoryValue === this.getActiveCategory(categoryName)) {
				categoryButton.classList.add('job-modal-category__button--active');
			}
		})
	}

	filterCategories(categoryName, categoryValue) {
		let finalOffers = this.offers;

		if (this.activeCategory[categoryName] === categoryValue) {
			delete this.activeCategory[categoryName];
		} else {
			this.activeCategory[categoryName] = categoryValue;
		}
		
		Object.keys(this.activeCategory).forEach((categoryKey) => {
			if (this.activeCategory[categoryKey]) {
				finalOffers = finalOffers.filter((category) => {
					return category[categoryKey] === this.activeCategory[categoryKey];
				});
			}
		});

		this.board.innerHTML = '';
		this.closeOffer();
		this.removeLoadMore();
		this.renderOffers(finalOffers);
	}

	renderOffers(offersArray = null, renderStart = false, renderEnd = false) {
		const offers = offersArray ? offersArray : this.offers;
		const sliceParameters = (renderEnd !== false && renderStart !== false) ? [renderStart, renderEnd] : [false];
		const markup = `
      ${offers
			.slice(...sliceParameters)
			.map((offer) =>
				`<li
					class="job-board__list__item js-job" 
					data-offer-id="${offer.id}"
				>
					<div class="container">
						<div class="job-board__list__item__container">
							<div class="job-board__list__item__position">
								<p>${offer.title}</p>
							</div>
							<div class="job-board__list__item__location">
								<p>${offer.location}</p>
							</div>
							<div class="job-board__list__item__arrow">
								<span></span>
							</div>
						</div>
					</div>
				</li>
		`).join('')}`;

		if (!offers.length) {
			this.handleAPIError('No results...');
		}
		
		this.board.innerHTML = this.board.innerHTML + markup;
		this.setEventListeners();

		document.querySelector('[data-scroll-container]').locomotive.update();
	}

	setEventListeners() {
		const offers = this.board.querySelectorAll('.js-job');

		offers.forEach(offer => {
			offer.addEventListener('click', () => {
				const { offerId } = offer.dataset;
				const offerData = this.getActiveOffer(offerId);
				this.showOffer(offerId, offerData);
			})
		})

		if (this.mode === 'career' && this.offers.length < this.offersLimit && this.removeLoadMore) {
			this.removeLoadMore();
			return;
		}

		if (this.mode === 'career') {
			const loadMoreButton = document.querySelector('.js-job-board-more-button');

			if (loadMoreButton) {
				loadMoreButton.addEventListener('click', () => {
					this.renderOffers(null, this.offersLimit, this.offersLength);
					this.removeLoadMore();
				});
			}
		}
	}

	setActiveOffer(offer) {
		this.activeOffer = offer;
	}

	getActiveOffer(offerId) {
		return this.offers.find(offer => offer.id == offerId);
	}

	getActiveCategory(key) {
		return this.activeCategory[key] ? this.activeCategory[key] : '';
	}

	removeLoadMore() {
		const loadMoreButton = document.querySelector('.js-job-board-more-button');
		if (loadMoreButton) loadMoreButton.remove(loadMoreButton);
	}

	createModalNode(classes, attributes) {
		const modal = document.createElement('div');
		modal.classList.add(...classes);

		attributes.forEach((attribute) => {
			modal.setAttribute(attribute.name, attribute.value);
		});

		return modal;
	}

	handleModalNativeEvents(modalNode, desktopClose, mobileClose) {
		modalNode.querySelector(desktopClose).addEventListener('click', () => {
			this.closeOffer();
		});

		modalNode.querySelector(mobileClose).addEventListener('click', () => {
			this.closeOffer();
		});

		const escPressHandler = () => {
			if (event.keyCode === 27) {
				this.closeOffer();
				document.removeEventListener('keydown', escPressHandler);
			}
		}

		document.addEventListener('keydown', escPressHandler);
	}

	openModal(modalNode) {
		document.body.insertBefore(modalNode, document.body.lastChild);

		setTimeout(() => {
			modalNode.classList.add('job-modal--is-open');
		}, 0);
	}

	closeModal(modalNode) {
		modalNode.classList.remove('job-modal--is-open');

		modalNode.addEventListener('transitionend', () => {
			modalNode.remove(modalNode);
		});
	}

	setURLParameter(offer) {
		const currentURL = new URL(window.location.href);
		currentURL.searchParams.set('offer', offer.slug);

		window.history.pushState(null, null, currentURL.toString());
	}

	deleteURLParameter() {
		const currentURL = new URL(window.location.href);
		currentURL.searchParams.delete('offer');

		window.history.pushState(null, null, currentURL.toString());
	}

	showOffer(id, offer) {
		this.setActiveOffer(offer);
		this.setURLParameter(offer);
		const modal = this.createModalNode(['job-modal'], [{
			name: 'data-modal-offer-id',
			value: id,
		}]);
		
		const modalMarkup = `
      <div class="job-modal__inner">
        <button class="bt-close job-modal__mobile-close"></button>
      
        <header class="job-modal__header">
          <button class="job-modal__back">
            <span class="job-modal__back__arrow"></span>
            <span class="job-modal__back__text caption">
              Back to Vay.io
            </span>
          </button>

          <h2 class="job-modal__header-title">
            ${offer.title}
          </h2>

          <span class="job-modal__header-location">
            ${offer.location}
          </span>
        </header>

        <div class="job-modal__content">
          <h3 class="job-modal__content-heading">
            Job description
          </h3>

          <div class="job-modal__content-data">
            ${offer.description}
          </div>

          <h3 class="job-modal__content-heading">
            Requirements
          </h3>

          <div class="job-modal__content-data">
            ${offer.requirements}
          </div>
        </div>

        <footer class="job-modal__footer">
          <a 
            href="${offer.careers_apply_url}" 
            target="_blank"
            class="job-modal__content-button button button--twilight"
          >
            Apply for this job
          </a>
        </footer> 
      </div>
    `;

		modal.innerHTML = modalMarkup;
		this.openModal(modal);
		this.handleModalNativeEvents(modal, '.job-modal__back', '.job-modal__mobile-close');
	}

	closeOffer() {
		this.setActiveOffer(null);
		this.deleteURLParameter();
		this.closeModal(document.querySelector('[data-modal-offer-id]'));
	}
}

export {JobBoard};