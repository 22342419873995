const imagesLoaded = require('imagesloaded');
import LocomotiveScroll from 'locomotive-scroll';
import {gsap, TweenMax, Expo, interpolate} from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import Swiper, { EffectFade } from 'swiper';
Swiper.use([EffectFade]);
// import { JobBoard } from './job-board';
import { slideDown, slideToggle, slideUp } from './utils';
// import Player from '@vimeo/player';
import Plyr from 'plyr';

gsap.registerPlugin(ScrollTrigger);




(function () {

    let Site = {
        scroll: undefined,
        hash: undefined,
        bgSections: [],
        cursor: document.querySelector('.js-cursor'),

        init: function () {
            Site.kodohouse();
            Site.initScroll();
            Site.initCursor();
            Site.initCursorEvents();
            Site.initNav();
            document.querySelector('.js-employees-slider') && Site.initEmployees();
            document.querySelector('.js-culture-slider') && Site.initCulture();
            document.querySelector('.js-benefits-slider') && Site.initBenefits();
            document.querySelector('.js-values-slider') && Site.initValues();
            document.querySelector('.js-lang-switcher') && Site.initLangSwitcher();
            
            document.querySelector('.js-article-slider') && Site.initArticleSlider();
            document.querySelector('.js-editorial-slider') && Site.initEditorialSlider();
            document.querySelector('.js-journal-category') && Site.initJournalCategories();
            document.querySelector('.js-supporter') && Site.initSupporters();
            document.querySelector('.js-news-post') && Site.initNews();
            document.querySelector('.js-play-full-video') && Site.initPlayer();
            document.querySelector('.js-contact-form-field') && Site.initContactForm();
            document.querySelector('.js-contact') && Site.initContact();
            document.querySelector('.js-subscribe-form') && Site.initSubscribe();
            document.querySelector('.js-company-form') && Site.initCompanyForm();
            document.querySelector('.js-safety-car') && Site.animateSafetyCar();
            document.querySelector('.js-human-machine') && Site.animateHumanMachine();
            document.querySelector('.js-job-board-list') && Site.initJobBoard();
            document.querySelector('.js-jobs-other-button') && Site.initJobsOther();
            document.querySelector('.js-teledriving-swiper') && Site.initTeledriving();
        },

        kodohouse: function () {
            console.log('Coded by kodo.house https://kodo.house/');
        },

        updateScroll: function () {
            Site.scroll.update();
            Site.generateSectionsMap();
            ScrollTrigger.refresh(); 
        },

        initScroll: function () {
            if (Site.scroll) {
                Site.scroll.destroy();
            }

            if (window.location.hash) {
                Site.hash = window.location.hash;
                history.replaceState({}, document.title, window.location.href.split('#')[0]);
            }

            Site.scroll = new LocomotiveScroll({
                el: document.querySelector('[data-scroll-container]'),
                smooth: true,
                repeat: false,
                getDirection: true,
                lerp: 0.1
            });
            Site.scroll.stop();

            if (document.querySelector('.js-hero')) {
                imagesLoaded( document.querySelector('.js-hero'), function( instance ) {
                    if (document.querySelector('.js-hero-video')) {
                        document.querySelector('.js-hero-video').play();
                    }
                    document.querySelector('body').classList.remove('is-loading');
                    if (Site.hash) {
                        setTimeout(function () {
                            Site.scroll.scrollTo(Site.hash);
                            Site.hash = null;
                        }, 700)
                    }
                    Site.scroll.start();
                });
            } 

            if (document.querySelector('.js-journal-featured-image')) {
                imagesLoaded( document.querySelector('.js-journal-featured-image'), function( instance ) {
                    document.querySelector('body').classList.remove('is-loading');
                    Site.scroll.update();
                    Site.scroll.start();
                });
            } 

            Site.generateSectionsMap();

            window.addEventListener('resize', function(event) {
                Site.generateSectionsMap();
            }, true);

            function between(x, min, max) {
                return x >= min && x <= max;
            }

            function findSection(bgSections, y) {
                bgSections.forEach(function(el) {
                    if(between(y+40, el.top, el.end)) {
                        document.querySelector('.js-header').dataset.color = el.color;
                    }
                })
            }

            Site.scroll.on('scroll', (args) => {
                findSection(Site.bgSections, args.scroll.y);
            });

            setTimeout(function () {
                document.querySelector('body').classList.remove('is-loading');
                Site.scroll.start();
            }, 1000);
        },

        initCursor: function () {
            window.addEventListener('pointermove', function(e) {
                if (e.target.classList.contains('js-full-video-bg')) {
                    Site.cursor.classList.add('is-play-video');
                } else {
                    Site.cursor.classList.remove('is-play-video');
                }
                if (Site.cursor.classList.contains('is-on-menu')) {
                    return
                }
                let left = e.pageX;
                let top = e.pageY;
                Site.cursor.style.left = left + 'px';
                Site.cursor.style.top = top + 'px';
            });
        },

        initCursorEvents: function () {
            const headerButton = document.querySelector('.js-header-button');
            const draggableEvening = document.querySelectorAll('.js-draggable-evening');
            const photoEls = document.querySelectorAll('.js-cursor-photo');

            Site.scroll.on('scroll', function () {
                Site.cursor.classList.remove('is-play-video');
            });
            
            photoEls.forEach(item => {
                item.addEventListener('mouseenter', event => {
                    Site.cursor.classList.add('is-photo');
                })

                item.addEventListener('mouseleave', event => {
                    Site.cursor.classList.remove('is-photo');
                })
            });

        draggableEvening.forEach(item => {
                item.addEventListener('mousemove', event => {
                    if (event.path[1].classList.contains('js-cursor-link')) {
                        Site.cursor.classList.remove('is-draggable-evening');
                        return;
                    }
                    Site.cursor.classList.add('is-draggable-evening');
                })

                item.addEventListener('mouseleave', event => {
                    Site.cursor.classList.remove('is-draggable-evening');
                })
            });

        const draggableBeam = document.querySelectorAll('.js-draggable-beam');

        draggableBeam.forEach(item => {
            item.addEventListener('mouseenter', event => {
                Site.cursor.classList.add('is-draggable-beam');
            })

            item.addEventListener('mouseleave', event => {
                Site.cursor.classList.remove('is-draggable-beam');
            })
            });

            const draggableMist = document.querySelectorAll('.js-draggable-mist');

        draggableMist.forEach(item => {
            item.addEventListener('mouseenter', event => {
                Site.cursor.classList.add('is-draggable-mist');
            })

            item.addEventListener('mouseleave', event => {
                Site.cursor.classList.remove('is-draggable-mist');
            })
            });

              headerButton.addEventListener('mouseenter', () => {
                Site.cursor.classList.add('is-on-menu');
                gsap.to(Site.cursor, 0.3, {
                    top: 53,
                    left: window.innerWidth - 73
                })
            });
        
            headerButton.addEventListener('mouseleave', () => {
                Site.cursor.classList.remove('is-on-menu');
            });
        },

        generateSectionsMap: function () {
            Site.bgSections = []
            document.querySelectorAll('.js-bg-change').forEach(function(el) {
                Site.bgSections.push({
                    el: el,
                    top: el.offsetTop,
                    end: el.offsetTop + el.getBoundingClientRect().height,
                    height: el.getBoundingClientRect().height,
                    color: el.dataset.bg
                })
            })
        },

        initNav: function () {
            const headerButton = document.querySelector('.js-header-button');
            const headerNav = document.querySelector('.js-header-nav');
            const headerNavLinks = document.querySelectorAll('.js-header-nav a, .js-footer a');
            const scrollLinks = document.querySelectorAll('.js-scroll-link');

            headerButton.addEventListener('click', function () {
                headerButton.classList.toggle('is-open');
                headerNav.classList.toggle('is-open');
            });

            headerNav.addEventListener('click', closeNav);

            headerNavLinks.forEach(item => {
                item.addEventListener('click', event => {
                    event.stopPropagation();
                    if (item.href.split('#')[1] == 'contact' && document.querySelector('#contact')) {
                        event.preventDefault();
                        Site.scroll.scrollTo('#contact');
                        closeNav();
                        return;
                    }
                    if (item.ariaCurrent == 'page') {
                        event.preventDefault();
                        let hash = item.href.split('#')[1];
                        if (hash) {
                            Site.scroll.scrollTo('#' + hash);
                        } else {
                            Site.scroll.scrollTo(0);
                        }
                        closeNav();    
                    }
                });
            });

            function closeNav() {
                headerButton.classList.remove('is-open');
                headerNav.classList.remove('is-open');
            }

            scrollLinks.forEach(item => {
                item.addEventListener('click', event => {
                    event.stopPropagation();
                    event.preventDefault();
                    let hash = item.href.split('#')[1];
                    Site.scroll.scrollTo('#' + hash);
                });
            });
        },

        animateSafetyCar: function () {
            const car = document.querySelector('.js-safety-car');

            Site.scroll.on('scroll', args => {
                if (window.innerWidth <= 1024) return;
                if (args.direction == 'up') {
                    car.classList.add('is-coming-back')
                } else {
                    car.classList.remove('is-coming-back')                    
                }
            });
        },

        animateHumanMachine: function () {
            Site.scroll.on('scroll', ScrollTrigger.update);

            ScrollTrigger.scrollerProxy('.js-scrollable', {
                scrollTop(value) {
                    return arguments.length ? Site.scroll.scrollTo(value, 0, 0) :    Site.scroll.scroll.instance.scroll.y;
                }, 
                getBoundingClientRect() {
                    return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
                }
            });

            ScrollTrigger.addEventListener("refresh", () => Site.scroll.update());
            ScrollTrigger.refresh(); 

            gsap.fromTo(
                '.circle.human',
                {
                    left: '-100%',
                    rotate: '-120deg'
                },
                {
                    left: 'calc(30%)',
                    rotate: '0deg',
                    scrollTrigger: {
                        scroller: '.js-scrollable',
                        trigger: '.js-human-machine',
                        start: 'top bottom',
                        end: 'top center',
                        scrub: 2,
                        toggleActions: 'restart pause reverse pause'
                    }
                }
            )
            gsap.fromTo(
                '.circle.human',
                {
                    y: 0
                },
                {
                    y: -50,
                    x: 20,
                    scrollTrigger: {
                        scroller: '.js-scrollable',
                        trigger: '.js-human-machine',
                        start: 'top center',
                        end: 'bottom center',
                        scrub: 0.2,
                        toggleActions: 'restart pause reverse pause'
                    }
                }
            )
            gsap.fromTo(
                '.circle.machine',
                {
                    left: '200%',
                    rotate: '110deg'
                },
                {
                    left: 'calc(70%)',
                    rotate: '0deg',
                    scrollTrigger: {
                        scroller: '.js-scrollable',
                        trigger: '.js-human-machine',
                        start: 'top bottom',
                        end: 'top center',
                        scrub: 2,
                        toggleActions: 'restart pause reverse pause'
                    }
                }
            )
            gsap.fromTo(
                '.circle.machine',
                {
                    y: 0
                },
                {
                    y: 50,
                    x: -20,
                    scrollTrigger: {
                        scroller: '.js-scrollable',
                        trigger: '.js-human-machine',
                        start: 'top center',
                        end: 'bottom center',
                        scrub: 0.2,
                        toggleActions: 'restart pause reverse pause',
                        onUpdate: self => {
                            if (self.progress.toFixed(3) > 0.45) {
                                document.querySelector('.machine').classList.add('vay-logo')
                            } else {
                                document.querySelector('.machine').classList.remove('vay-logo')
        
                            }
                          }
                    }
                }
            )
        },

        initBenefits: function () {
            const swiper = new Swiper('.js-benefits-slider', {
                speed: 600,
                slidesPerView : 'auto',
                centeredSlides: true,
                loop: true,
                spaceBetween: 16,
                touchRatio: 0.5,
                threshold: 7,
                on: {
                    click: function (swiper) {
                        if (swiper.clickedSlide.classList.contains('swiper-slide-next')) {
                            swiper.slideNext();
                        } else if (swiper.clickedSlide.classList.contains('swiper-slide-prev')) {
                            swiper.slidePrev();
                        } 
                    },
                },
                breakpoints: {
                    601: {
                        speed: 900,
                        spaceBetween: 0,
                    }
                  }
            });
        },

        initTeledriving: function () {
            const teledrivingContainer = document.querySelector('.js-teledriving-swiper');
            const teledrivingSwiper = new Swiper('.js-teledriving-swiper', {
                speed: 600,
                effect: 'fade',
                slidesPerView: 1,
                fadeEffect: {
                    crossFade: true
                },
                on: {
                    slideChange: function (swiper) {
                        teledrivingContainer.dataset.step = swiper.activeIndex + 1;
                    }
                }
            });

            teledrivingContainer.querySelectorAll('.js-next').forEach(item => {
                item.addEventListener('click', function () {
                    teledrivingSwiper.slideTo(item.dataset.index);
                    if (window.innerWidth <= 768) {
                        return;
                    }
                    if (item.dataset.index == 0) {
                        teledrivingVideo.pause();
                        teledrivingVideo.currentTime = 0;
                    }
                    teledrivingVideo.play();
                })
            });

            const teledrivingVideo = document.querySelector('.js-teledriving-video');

            teledrivingVideo.addEventListener('timeupdate', (event) => {
                if (window.innerWidth <= 768) {
                    return;
                }

                if (teledrivingContainer.dataset.step == '1' && teledrivingVideo.currentTime > 2.5 && teledrivingVideo.currentTime < 10) {
                    teledrivingVideo.pause();
                }

                if (teledrivingContainer.dataset.step == '2' && teledrivingVideo.currentTime > 6.9) {
                    teledrivingVideo.pause();
                }

                if (teledrivingContainer.dataset.step == '3' && teledrivingVideo.currentTime > 10.9) {
                    teledrivingVideo.pause();
                }
            });

            teledrivingVideo.addEventListener('play', (event) => {
                if (window.innerWidth <= 768) {
                    return;
                }
                teledrivingContainer.classList.add('is-playing');
            });

            teledrivingVideo.addEventListener('pause', (event) => {
                teledrivingContainer.classList.remove('is-playing');
            });

            Site.scroll.on('call', (func, dir, obj) => {
                if (func == 'play_teledriving') {
                    if (teledrivingVideo.classList.contains('is-init')) {
                        return;
                    }
                    if (dir == 'enter') {
                        teledrivingVideo.classList.add('is-init');
                        teledrivingVideo.play();
                    } 
                }
            });
        },

        initArticleSlider: function () {
            document.querySelectorAll('.js-article-slider').forEach(item => {
                item.swiper = new Swiper(item, {
                    speed: 600,
                    slidesPerView : 'auto',
                    loop: true,
                    touchRatio: 0.5,
                    threshold: 7,
                    on: {
                        click: function (swiper) {
                            if (swiper.clickedSlide.classList.contains('swiper-slide-next')) {
                                swiper.slideNext();
                            } else if (swiper.clickedSlide.classList.contains('swiper-slide-prev')) {
                                swiper.slidePrev();
                            } 
                        },
                    },
                    breakpoints: {
                        601: {
                            speed: 900,
                            centeredSlides: true
                        }
                      }
                });
            });
        },

        initEditorialSlider: function () {
            const swiper = new Swiper('.js-editorial-slider', {
                speed: 600,
                slidesPerView : 'auto',
                loop: true,
                touchRatio: 0.5,
                threshold: 7,
                on: {
                    click: function (swiper) {
                        if (swiper.clickedSlide.classList.contains('swiper-slide-next')) {
                            swiper.slideNext();
                        } else if (swiper.clickedSlide.classList.contains('swiper-slide-prev')) {
                            swiper.slidePrev();
                        } 
                    },
                },
                breakpoints: {
                    601: {
                        speed: 900,
                        centeredSlides: true
                    }
                    }
            });
        },

        initJournalCategories: function () {
            document.querySelectorAll('.js-journal-category').forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.js-journal-category.is-active').classList.remove('is-active');
                    item.classList.add('is-active');
                    let categoryId = item.dataset.id;
                    let postsToShow = document.getElementsByClassName(`js-journal-post ${categoryId}`);
                    showPosts(postsToShow)
                });
            });

            function showPosts(postsToShow) {
                document.querySelectorAll('.js-journal-post.is-active').forEach(item => {
                    item.classList.remove('is-active');
                });

                for (let item of postsToShow) {
                    item.classList.add('is-active');
                }

                document.querySelectorAll('.js-journal-post:not(.is-active)').forEach(item => {
                    item.style.display = "none";
                    item.classList.remove('is-visible');
                });

                for (let item of postsToShow) {
                    item.style.display = "block";
                    setTimeout(function () {
                        item.classList.add('is-visible');
                    }, 10);
                }

                Site.scroll.update();
            }
        },

        initEmployees: function () {

            const swiper = new Swiper('.js-employees-slider', {
                speed: 600,
                slidesPerView : 'auto',
                loop: true,
                touchRatio: 0.5,
                threshold: 7,
                on: {
                    click: function (swiper) {
                        setTimeout(function () {
                            if (!swiper.clickedSlide.classList.contains('swiper-slide-active')) {
                                swiper.slideNext();
                            }
                        }, 50);
                    },
                },
                breakpoints: {
                    601: {
                        speed: 900,
                        centeredSlides: true
                    }
                  }
            });

            const employeeImages = document.querySelectorAll('.js-employee-image');

            employeeImages.forEach(item => {
                item.addEventListener('click', event => {
                    if (!item.closest('.js-employee-slide').classList.contains('swiper-slide-active')) {
                        return;
                    }
                    Site.scroll.stop();
                    document.querySelector('body').classList.add('is-locked');
                    let viewportOffset = item.getBoundingClientRect();
                    let top = viewportOffset.top;
                    let left = viewportOffset.left;
                    let width = viewportOffset.width;
                    let height = viewportOffset.height;

                    let index = item.parentNode.dataset.employeeIndex;

                    let popupItem = document.querySelector(`.js-employee[data-employee-index="${index}"]`);
                    let popupImage = popupItem.querySelector('.js-employee-popup-image');

                    popupItem.style.display = 'block';

                    let targetViewportOffset = popupImage.getBoundingClientRect();
                    let targetWidth = targetViewportOffset.width;
                    let targetHeight = targetViewportOffset.height;

                    gsap.set(popupImage, {
                        width: width,
                        height: height,
                        x: left,
                        y: top,
                        borderRadius: 16,
                        opacity: 1,
                        onComplete: function () {
                            item.classList.add('is-clicked');
                        }
                    });

                    gsap.to(popupImage, {
                        width: targetWidth,
                        height: targetHeight,
                        x: 0,
                        y: 0,
                        borderRadius: 0,
                        duration: 0.9,
                        ease: 'expo'
                    });

                    setTimeout(function () {
                        popupItem.classList.add('is-active');
                    }, 50);
                });
            });

            const employeeNames = document.querySelectorAll('.employees__slide__content');

            employeeNames.forEach(item => {
                item.addEventListener('click', event => {
                    item.closest('.js-employee-slide').querySelector('.js-employee-image').click();
                });
            });

            const employeeCloses = document.querySelectorAll('.js-employee-close');

            employeeCloses.forEach(item => {
                item.addEventListener('click', event => {

                    let employee = item.parentElement;
                    let index = employee.dataset.employeeIndex;

                    let sliderImage = document.querySelector(`.js-employee-image.is-clicked`);

                    let viewportOffset = sliderImage.getBoundingClientRect();
                    let top = viewportOffset.top;
                    let left = viewportOffset.left;
                    let width = viewportOffset.width;
                    let height = viewportOffset.height;

                    let popupImage = employee.querySelector('.js-employee-popup-image');

                    let targetViewportOffset = popupImage.getBoundingClientRect();
                    let targetWidth = targetViewportOffset.width;
                    let targetHeight = targetViewportOffset.height;

                    gsap.to(popupImage, {
                        width: width,
                        height: height,
                        x: left,
                        y: top,
                        borderRadius: 16,
                        duration: 0.6,
                        ease: 'expo',
                        onComplete: function () {
                            gsap.set(popupImage, {
                                width: targetWidth,
                                height: targetHeight,
                            });
                            sliderImage.classList.remove('is-clicked');
                            employee.style.display = 'none';
                            Site.scroll.start();
                            document.querySelector('body').classList.remove('is-locked');
                        }
                    });

                    employee.classList.remove('is-active');
                });
            });

            function updateSize() {
                if (document.querySelector('.js-employee.is-active')) {
                    let activeMember = document.querySelector('.js-employee.is-active');
                    if (window.innerWidth > 600) {
                        gsap.set(activeMember.querySelector('.js-employee-popup-image'), {
                            width:  window.innerWidth * 0.40,
                            height: window.innerHeight
                        })
                    } else {
                        gsap.set(activeMember.querySelector('.js-employee-popup-image'), {
                            width:  window.innerWidth,
                            height: window.innerWidth * 0.7
                        })
                    }
                }
            }

            window.addEventListener('resize', function(event) {
                updateSize();
            }, true);

        },

        initCulture: function () {
            let swiper;

            function initCultureSlider() {
                swiper = new Swiper('.js-culture-slider', {
                    speed: 600,
                    slidesPerView : 'auto',
                    spaceBetween: 16,
                    loop: true,
                    centeredSlides: true,
                    resizeObserver: false,
                    touchRatio: 0.5,
                    threshold: 7,    
                    on: {
                        click: function (swiper) {
                            // swiper.slideTo(swiper.clickedIndex);
                            if (swiper.clickedIndex < swiper.activeIndex) {
                                swiper.slidePrev();
                            } else {
                                swiper.slideNext();
                            }
                        }
                    },
                    breakpoints: {
                        601: {
                            speed: 900
                        },
                        769: {
                            spaceBetween: 30
                        },
                        1200: {
                            spaceBetween: 130
                        }
                    }
                });
            }

            setTimeout(function() {
                initCultureSlider();
            }, 500)

            window.onresize = updateSize;

            function updateSize() {
                swiper.destroy();
                initCultureSlider();
            }
        },

        initLangSwitcher: function () {
            document.querySelectorAll('.js-lang-switcher').forEach(item => {
                item.addEventListener('change', function(e) {
                    location.href = this.options[this.selectedIndex].dataset.href;
                })

                item.addEventListener('click', function(e) {
                    e.stopPropagation();
                })
            });
        },

        initValues: function () {
            const swiper = new Swiper('.js-values-slider', {
                speed: 600,
                slidesPerView : 'auto',
                spaceBetween: 30,
                preventClicks: false,
                touchRatio: 0.5,
                threshold: 7,

                601: {
                    speed: 900
                }
            });

            const values = document.querySelectorAll('.js-value');

            values.forEach(item => {
                item.timeout = null;

                item.addEventListener('mouseenter', event => {
                    if (window.innerWidth <= 1024) return;
                    if (item.timeout != null) { clearTimeout(item.timeout); }
                    item.timeout = setTimeout(function () {
                        item.classList.add('is-hover');
                    }, 100);
                })

                item.addEventListener('mouseleave', event => {
                    if (window.innerWidth <= 1024) return;
                    item.classList.remove('is-hover');
                    if (item.timeout != null) {
                        clearTimeout(item.timeout);
                        item.timeout = null;
                     }
                })

                item.addEventListener('click', event => {
                    if (window.innerWidth > 600) return;

                    item.style.pointerEvents = 'none';

                    if (item.classList.contains('is-active')) {
                        item.classList.remove('is-active');
                        slideUp(item.querySelector('.js-value-text'), 280, function () {
                            Site.updateScroll();
                            item.style.pointerEvents = 'auto';
                        });
                        return;
                    }

                    if (document.querySelector('.js-value.is-active')) {
                        slideUp(document.querySelector('.js-value.is-active .js-value-text'), 280, function () {
                            Site.updateScroll();
                        });
                        document.querySelector('.js-value.is-active').classList.remove('is-active');

                    }

                    item.classList.add('is-active');
                    slideDown(item.querySelector('.js-value-text'), 280, function () {
                        Site.updateScroll();
                        item.style.pointerEvents = 'auto';
                    });
                })
            });
        },

        initSupporters: function () {
            const supporters = document.querySelectorAll('.js-supporter');

            supporters.forEach(item => {
                item.timeout = null;

                item.addEventListener('mouseenter', event => {
                    if (window.innerWidth <= 1024) return;
                    if (item.timeout != null) { clearTimeout(item.timeout); }
                    item.timeout = setTimeout(function () {
                        item.classList.add('is-hover');
                    }, 100);
                })

                item.addEventListener('mouseleave', event => {
                    if (window.innerWidth <= 1024) return;
                    item.classList.remove('is-hover');
                    if (item.timeout != null) {
                        clearTimeout(item.timeout);
                        item.timeout = null;
                     }
                })

                item.addEventListener('click', event => {
                    if (window.innerWidth > 1024) return;
                    item.classList.toggle('is-hover');
                })
            });
        },

        initPlayer: function () {
            const fullVideo = document.querySelector('.js-plyr-video');
            const heroVideo = document.querySelector('.js-hero-video');

            fullVideo.player = new Plyr(fullVideo, {
                // hideControls: true
                controls: false
                // controls: ['play-large', 'play', 'progress', 'pip', 'airplay', 'fullscreen']
            });

            const fullVideoContainer = document.querySelector('.js-plyr-video-container');

            fullVideoContainer.addEventListener('click', function () {
                // if (window.innerWidth <= 1024) return;
                if (fullVideoContainer.classList.contains('is-playing')) {
                    pauseFullVideo();
                } else {
                    playFullVideo();
                }
            });

            document.querySelector('.js-play-full-video').addEventListener('click', function () {
                if (window.innerWidth <= 1024) return;
                if (fullVideoContainer.classList.contains('is-playing')) {
                    pauseFullVideo();
                } else {
                    playFullVideo();
                }
            });

            document.querySelector('.js-play-video-trigger').addEventListener('click', function () {
                if (fullVideoContainer.classList.contains('is-playing')) {
                    pauseFullVideo();
                } else {
                    playFullVideo();
                }
            });

            function playFullVideo() {
                fullVideo.player.play();
                fullVideoContainer.classList.add('is-playing');
                heroVideo.pause();
                Site.scroll.stop();
                Site.cursor.classList.add('is-close-video');
            }

            function pauseFullVideo() {
                fullVideo.player.pause();
                fullVideoContainer.classList.remove('is-playing');
                heroVideo.play();
                Site.scroll.start();
                Site.cursor.classList.remove('is-close-video');
            }
        },

        initHeroVideos: function () {

            const heroVideo = document.querySelector('.js-hero-video');
            const videoUrl = heroVideo.dataset.vimeoUrla;

            let options = {
                url: videoUrl,
                width: 1920,
                quality: '720p',
                loop: true,
                responsive: true,
                background: true,
                autoplay: true,
                muted: true,
                controls: false
            };

            let heroPlayer = new Player(heroVideo, options);

            heroPlayer.on('play', function(data) {
                document.querySelector('body').classList.remove('is-loading');
                Site.scroll.start();
                if (Site.hash) {
                    setTimeout(function () {
                        Site.scroll.scrollTo(Site.hash);
                        Site.hash = null;
                    }, 700)
                }
            });

            const fullVideo = document.querySelector('.js-full-video');
            const fullVideoContainer = fullVideo.parentElement;
            const fullVideoUrl = fullVideo.dataset.vimeoUrla;
            let fullOptions = {
                url: fullVideoUrl,
                width: 1200,
                api: false,
                quality: '720p',
                loop: false,
                responsive: true,
                autoplay: false,
                muted: false,
                controls: false
            };

            fullVideo.player = new Player(fullVideo, fullOptions);

            fullVideo.player.on('play', function () {
                // playFullVideo();
            });

            fullVideo.player.on('pause', function () {
                // pauseFullVideo();
            });

            fullVideoContainer.addEventListener('click', function () {
                if (window.innerWidth <= 1024) return;
                if (fullVideoContainer.classList.contains('is-playing')) {
                    pauseFullVideo();
                } else {
                    playFullVideo();
                }
            });

            document.querySelector('.js-play-full-video').addEventListener('click', function () {
                if (window.innerWidth <= 1024) return;
                if (fullVideoContainer.classList.contains('is-playing')) {
                    pauseFullVideo();
                } else {
                    playFullVideo();
                }
            });

            function playFullVideo() {
                fullVideo.player.play();
                fullVideoContainer.classList.add('is-playing');
                heroPlayer.pause();
                Site.scroll.stop();
                Site.cursor.classList.add('is-close-video');
            }

            function pauseFullVideo() {
                fullVideo.player.pause();
                fullVideoContainer.classList.remove('is-playing');
                heroPlayer.play();
                Site.scroll.start();
                Site.cursor.classList.remove('is-close-video');
            }

        },

        initVideos: function () {
            const videos = document.querySelectorAll('.js-video');
            videos.forEach(video => {
                const videoUrl = video.dataset.vimeoUrla;
                let options = {
                    url: videoUrl,
                    width: 1200,
                    api: false,
                    quality: '720p',
                    loop: false,
                    responsive: true,
                    autoplay: false,
                    muted: false,
                    controls: false
                };

                video.player = new Player(video, options);
                video.player.on('loaded', function () {
                    Site.updateScroll();
                });

                

                const videoContainer = video.parentNode;

                video.player.on('ended', function () {
                    video.player.setCurrentTime(0).then(function(seconds) {
                      }).catch(function(error) {
                        switch (error.name) {
                          case 'RangeError':
                              break;                      
                          default:
                              break;
                        }
                      });
                    videoContainer.classList.remove('is-playing');
                });

                video.player.on('play', function () {
                    videoContainer.classList.add('is-playing');
                });

                video.player.on('pause', function () {
                    videoContainer.classList.remove('is-playing');
                });

                videoContainer.addEventListener('click', function () {
                    if (videoContainer.classList.contains('is-playing')) {
                        video.player.pause();
                    } else {
                        video.player.play();
                    }
                });

                if (video.nextElementSibling) {
                    const playButton = video.nextElementSibling;

                    playButton.addEventListener('click', function (e) {
                        e.stopPropagation();
                        video.player.play();
                        videoContainer.classList.add('is-playing');
                    });
                }
                
            });
        },

        initCompanyForm: function () {
            document.querySelector('.js-company-form-button').addEventListener('click', function (e) {
                e.stopPropagation();
                document.querySelector('.js-company-form').classList.add('is-active');
            });

            document.querySelector('.js-contact-company').addEventListener('click', function () {
                document.querySelector('.js-company-form').classList.remove('is-active');

            });

            document.querySelector('.js-company-form').addEventListener('click', function (e) {
                e.stopPropagation();
            });

            document.querySelector('.js-company-form .js-contact-form-close').addEventListener('click', function () {
                document.querySelector('.js-company-form').classList.remove('is-active');
            });
        },

        initSubscribe: function () {
            document.querySelector('.js-subscribe-button').addEventListener('click', function (e) {
                e.stopPropagation();
                document.querySelector('.js-subscribe-form').classList.add('is-active');
                document.querySelector('.js-header-button').classList.add('is-hidden');
            });

            document.querySelector('.js-subscribe').addEventListener('click', function () {
                document.querySelector('.js-subscribe-form').classList.remove('is-active');
                document.querySelector('.js-header-button').classList.remove('is-hidden');

            });

            document.querySelector('.js-subscribe-form').addEventListener('click', function (e) {
                e.stopPropagation();
            });

            document.querySelector('.js-subscribe-form .js-contact-form-close').addEventListener('click', function () {
                document.querySelector('.js-subscribe-form').classList.remove('is-active');
                document.querySelector('.js-header-button').classList.remove('is-hidden');
            });
        },

        initContactForm: function () {
            const contactInputs = document.querySelectorAll('.js-contact-form-field input[type="text"], .js-contact-form-field input[type="email"]');

            contactInputs.forEach(item => {
                item.addEventListener('keyup', event => {
                    let field = item.closest('.js-contact-form-field');
                    let placeholder = field.querySelector('.js-contact-form-placeholder');
                    let counter = field.querySelector('.js-contact-form-field-counter-value');
                    if (item.value.length > 0) {
                        placeholder.classList.add('is-visible');
                    } else {
                        placeholder.classList.remove('is-visible');
                    }
                    if (counter) {
                        counter.innerText = item.value.length;
                    }
                });
            });

            if (document.querySelector('.js-contact input[type="file"]')) {
                document.querySelector('.js-contact input[type="file"]').addEventListener('change', e => {
                    document.querySelector('.js-contact-form-field-file-info').innerText = e.target.files[0].name;
                });
            }
        },

        initContact: function () {
            const sections = document.querySelectorAll('.js-contact-section');
            const buttons = document.querySelectorAll('.js-contact-section-button');
            const contact = document.querySelector('.js-contact');
            const closeBtns = document.querySelectorAll('.js-contact-form-close');

            contact.addEventListener('mouseleave', event => {
                if (window.innerWidth <= 768) return;
                contact.dataset.active = '';
            });

            buttons.forEach(item => {
                item.addEventListener('click', event => {
                    document.querySelector('.js-header-button').classList.add('is-hidden');
                    contact.dataset.active = item.closest('.js-contact-section').dataset.side;
                });
            });

            closeBtns.forEach(item => {
                item.addEventListener('click', event => {
                    contact.dataset.active = '';
                    document.querySelector('.js-header-button').classList.remove('is-hidden');

                });
            });

            sections.forEach(item => {
                item.timeout = null;

                item.addEventListener('mousemove', event => {
                    if (window.innerWidth <= 768) return;
                    if (item.timeout != null) { clearTimeout(item.timeout); }
                    item.timeout = setTimeout(function () {
                        contact.dataset.active = item.dataset.side;
                    }, 50);
                });

                item.addEventListener('mouseleave', event => {
                    if (window.innerWidth <= 768) return;
                    if (item.timeout != null) {
                        clearTimeout(item.timeout);
                        item.timeout = null;
                     }
                });
            });

            
        },

        initNews: function () {
            const news = document.querySelectorAll('.js-news-post');
            const newsMore = document.querySelector('.js-see-more-news');
            const newsButtons = document.querySelectorAll('.js-news-post-button');

            newsButtons.forEach(item => {
                item.addEventListener('click', event => {
                    event.stopPropagation();
                });
            });

            newsMore.addEventListener('click', event => {
                newsMore.style.display = 'none';
                event.preventDefault();
                document.querySelectorAll('.js-news-post:not(.is-visible)').forEach(item => {
                    item.style.display = 'block';
                    item.classList.add('is-visible');
                });
                Site.updateScroll();
            });

            news.forEach(item => {
                item.timeout = null;

                item.addEventListener('mouseenter', event => {
                    if (window.innerWidth <= 1024) return;
                    if (item.timeout != null) { clearTimeout(item.timeout); }
                    item.timeout = setTimeout(function () {
                        item.classList.add('is-hover');
                    }, 100);
                });

                item.addEventListener('mouseleave', event => {
                    if (window.innerWidth <= 1024) return;
                    item.classList.remove('is-hover');
                    if (item.timeout != null) {
                        clearTimeout(item.timeout);
                        item.timeout = null;
                     }
                });

                item.addEventListener('click', event => {
                    item.style.pointerEvents = 'none';

                    if (item.classList.contains('is-active')) {
                        item.classList.remove('is-active');
                        slideUp(item.querySelector('.js-news-post-content'), 250, function () {
                            Site.updateScroll();
                            item.style.pointerEvents = 'auto';
                        });
                        return;
                    }

                    if (document.querySelector('.js-news-post.is-active')) {
                        slideUp(document.querySelector('.js-news-post.is-active .js-news-post-content'), 250, function () {
                            Site.updateScroll();
                        });
                        document.querySelector('.js-news-post.is-active').classList.remove('is-active');

                    }

                    item.classList.add('is-active');
                    slideDown(item.querySelector('.js-news-post-content'), 250, function () {
                        Site.updateScroll();
                        item.style.pointerEvents = 'auto';
                    });

                })

            });
        },

        initJobsOther: function () {
            document.querySelector('.js-jobs-other-button').addEventListener('click', function () {
                Site.scroll.scrollTo('#contact');
                document.querySelector('.js-contact').dataset.active = 'right';
            });
        },

        initJobBoard: function () {
            // Helper function
            const getRouteName = () => {
                const path = window.location.pathname;

                if (path.length === 0 || path.length === 1) return 'home';
                else if (path.includes('career')) return 'career';
                else return false;
            }

            class JobBoard {
                constructor(board, offersLimit, mode = 'home') {
                    this.apiURL = 'https://vayio.recruitee.com/api';
                    this.board = board;
                    this.offers = [];
                    this.activeOffer = null;
                    this.activeCategory = {};
                    this.offersLimit = offersLimit;
                    this.mode = mode;
                    this.categories = {};
                    this.offersLength = 0;
            
                    this.fetchJobs();
                }
            
                checkURL() {
                    const windowURL = new URL(window.location.href);
                    const params = new URLSearchParams(windowURL.search);
                    const offerSlug = params.get('offer');
            
                    if (offerSlug && offerSlug.length > 0) {
                        const offer = this.offers.find((offer) => offer.slug === offerSlug);
                        if (offer) this.showOffer(offer.id, offer);
                    }
                }
            
                async fetchJobs() {
                    try {
                        const response = await fetch(`${this.apiURL}/offers/`);
                        if (response.status !== 200 || !response.ok) throw new Error('Error');
                        const data = await response.json();
            
                        this.offers = data.offers;
                        this.offersLength = data.offers.length;
                        this.renderOffers(this.offers, 0, this.offersLimit);
                        this.checkURL();
            
                        if (this.mode === 'career') this.createCategories();
                    } catch (err) {
                        this.handleAPIError();
                        console.log(err);
                    }
                }
            
                handleAPIError(errorMessage = 'Currently there are no offers') {
                    const markup = `
                  <div class="container">
                    <h4 class="job-error">
                      ${errorMessage}
                    </h3>
                  </div>
                `;
            
                    this.board.innerHTML = markup;
                    if (this.mode === 'career') this.removeLoadMore();
                }
            
                createCategories() {
                    let allDepartmentCategories = [];
                    let allLocationCategories = [];
            
                    this.offers.forEach((offer) => {
                        if (offer.department) allDepartmentCategories.push(offer.department);
                        if (offer.location) allLocationCategories.push(offer.location);
                    });
            
                    this.categories = {
                        location: [...new Set(allLocationCategories)],
                        department: [...new Set(allDepartmentCategories)],
                    }
            
                    this.createCategoriesSelect();
                }
            
                createCategoriesSelect() {
                    const filtersRoot = document.querySelector('#filters-selects');
                    const citiesLabel = filtersRoot.dataset.cities;
                    const teamsLabel = filtersRoot.dataset.teams;
                    const filtersMarkup = `
                        ${Object.keys(this.categories).map(categoryKey =>
                            `
                                <select class="filters__select" name="${categoryKey}">
                                    <option selected value="">${categoryKey === 'department' ? teamsLabel : citiesLabel}</option>
                                    ${this.categories[categoryKey]
                                        .map(
                                            category =>
                                                `
                                                    <option value="${category}">
                                                        ${category}
                                                    </option>
                                                `
                                        ).join('')}
                                </select>
                            `
                        ).join('')}
                    `;
            
                    filtersRoot.innerHTML = filtersMarkup;
                            
                    setTimeout(() => {
                        const allSelects = document.querySelectorAll('.filters__select');
            
                        allSelects.forEach((select) => {
                            select.addEventListener('change', (event) => {
                                this.filterCategories(event.target.name, event.target.value);
                            });
                        });
                    }, 0);		
                }
            
                filterCategories(categoryName, categoryValue) {
                    let finalOffers = this.offers;
            
                    if (this.activeCategory[categoryName] === categoryValue) {
                        delete this.activeCategory[categoryName];
                    } else {
                        this.activeCategory[categoryName] = categoryValue;
                    }
                    
                    Object.keys(this.activeCategory).forEach((categoryKey) => {
                        if (this.activeCategory[categoryKey]) {
                            finalOffers = finalOffers.filter((category) => {
                                return category[categoryKey] === this.activeCategory[categoryKey];
                            });
                        }
                    });
            
                    this.board.innerHTML = '';
                    this.removeLoadMore();
                    this.renderOffers(finalOffers);
                }
            
                renderOffers(offersArray = null, renderStart = false, renderEnd = false) {
                    const offers = offersArray ? offersArray : this.offers;
                    const sliceParameters = (renderEnd !== false && renderStart !== false) ? [renderStart, renderEnd] : [false];
                    const markup = `
                    ${offers
                        .slice(...sliceParameters)
                        .map((offer) =>
                            `<li
                                class="job-board__list__item js-job" 
                                data-offer-id="${offer.id}"
                            >
                                <div class="container">
                                    <div class="job-board__list__item__container">
                                        <div class="job-board__list__item__position">
                                            <p>${offer.title}</p>
                                        </div>
                                        <div class="job-board__list__item__location">
                                            <p>${offer.location}</p>
                                        </div>
                                        <div class="job-board__list__item__arrow">
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                    `).join('')}`;
            
                    if (!offers.length) {
                        this.handleAPIError('No results...');
                    }
                    
                    this.board.innerHTML = this.board.innerHTML + markup;
                    this.setEventListeners();
            
                    // Need to refresh ScrollTrigger to get proper marks
                    setTimeout(() => {
                        Site.updateScroll();
                        ScrollTrigger.refresh();
                    }, 0);
                }
            
                setEventListeners() {
                    const offers = this.board.querySelectorAll('.js-job');
            
                    offers.forEach(offer => {
                        offer.addEventListener('click', () => {
                            const { offerId } = offer.dataset;
                            const offerData = this.getActiveOffer(offerId);
                            this.showOffer(offerId, offerData);
                        })
                    })
            
                    if (this.mode === 'career' && this.offers.length < this.offersLimit && this.removeLoadMore) {
                        this.removeLoadMore();
                        return;
                    }
            
                    if (this.mode === 'career') {
                        const loadMoreButton = document.querySelector('#job-board-more-button');
            
                        if (loadMoreButton) {
                            loadMoreButton.addEventListener('click', () => {
                                this.renderOffers(null, this.offersLimit, this.offersLength);
                                this.removeLoadMore();
                            });
                        }
                    }
                }
            
                setActiveOffer(offer) {
                    this.activeOffer = offer;
                }
            
                getActiveOffer(offerId) {
                    return this.offers.find(offer => offer.id == offerId);
                }
            
                getActiveCategory(key) {
                    return this.activeCategory[key] ? this.activeCategory[key] : '';
                }
            
                removeLoadMore() {
                    const loadMoreButton = document.querySelector('#job-board-more-button');
                    if (loadMoreButton) loadMoreButton.remove(loadMoreButton);
                }
            
                createModalNode(classes, attributes) {
                    const modal = document.createElement('div');
                    modal.classList.add(...classes);
            
                    attributes.forEach((attribute) => {
                        modal.setAttribute(attribute.name, attribute.value);
                    });
            
                    return modal;
                }
            
                handleModalNativeEvents(modalNode, desktopClose, mobileClose) {
                    modalNode.querySelector(desktopClose).addEventListener('click', () => {
                        this.closeOffer();
                    });
            
                    modalNode.querySelector(mobileClose).addEventListener('click', () => {
                        this.closeOffer();
                    });
            
                    const escPressHandler = () => {
                        if (event.keyCode === 27) {
                            this.closeOffer();
                            document.removeEventListener('keydown', escPressHandler);
                        }
                    }
            
                    document.addEventListener('keydown', escPressHandler);
                }
            
                openModal(modalNode) {
                    document.body.insertBefore(modalNode, document.body.lastChild);
            
                    setTimeout(() => {
                        modalNode.classList.add('job-modal--is-open');
                    }, 0);
                }
            
                closeModal(modalNode) {
                    modalNode.classList.remove('job-modal--is-open');
            
                    modalNode.addEventListener('transitionend', () => {
                        modalNode.remove(modalNode);
                    });
                }
            
                setURLParameter(offer) {
                    const currentURL = new URL(window.location.href);
                    currentURL.searchParams.set('offer', offer.slug);
                    currentURL.searchParams.set('info', 'We are hiring. Work at Vay. Join us.');
            
                    window.history.pushState(null, null, currentURL.toString());
                }
            
                deleteURLParameter() {
                    const currentURL = new URL(window.location.href);
                    currentURL.searchParams.delete('offer');
                    currentURL.searchParams.delete('info');
            
                    window.history.pushState(null, null, currentURL.toString());
                }
            
                showOffer(id, offer) {
                    this.setActiveOffer(offer);
                    this.setURLParameter(offer);
                    const modal = this.createModalNode(['job-modal'], [{
                        name: 'data-modal-offer-id',
                        value: id,
                    }]);
                    
                    const modalMarkup = `
                <div class="job-modal__inner">
                    <button class="bt-close job-modal__mobile-close"></button>
                
                    <header class="job-modal__header">
                    <button class="job-modal__back">
                        <span class="job-modal__back__arrow"></span>
                        <span class="job-modal__back__text">
                            Back
                        </span>
                    </button>

                    <h2 class="job-modal__header-title">
                        ${offer.title}
                    </h2>

                    <span class="job-modal__header-location">
                        ${offer.location}
                    </span>
                    </header>

                    <div class="job-modal__content">
                    <h3 class="job-modal__content-heading">
                        Job description
                    </h3>

                    <div class="job-modal__content-data">
                        ${offer.description}
                    </div>

                    <h3 class="job-modal__content-heading">
                        Requirements
                    </h3>

                    <div class="job-modal__content-data">
                        ${offer.requirements}
                    </div>
                    </div>

                    <footer class="job-modal__footer">
                    <a 
                        href="${offer.careers_apply_url}" 
                        target="_blank"
                        class="job-modal__content-button button button--twilight"
                    >
                        Apply for this job
                    </a>
                    </footer> 
                </div>
                `;

                    modal.innerHTML = modalMarkup;
                    this.openModal(modal);
                    this.handleModalNativeEvents(modal, '.job-modal__back', '.job-modal__mobile-close');
                }
            
                closeOffer() {
                    this.setActiveOffer(null);
                    this.deleteURLParameter();
                    this.closeModal(document.querySelector('[data-modal-offer-id]'));
                    document.querySelector('body').classList.remove('lightbox');
                }
            }

                const jobBoardList = document.querySelector('.js-job-board-list');
            new JobBoard(jobBoardList, jobBoardList.dataset.jobsVisible, 'career');

        }

        // initJobBoard: function () {

        //     // Helper function
        //     const getRouteName = () => {
        //         const path = window.location.pathname;

        //         if (path.length === 0 || path.length === 1) return 'home';
        //         else if (path.includes('career')) return 'career';
        //         else return false;
        //     }


        //     class JobBoard {
        //         constructor(board, offersLimit, mode = 'home') {
        //             this.apiURL = 'https://vayio.recruitee.com/api';
        //             this.board = board;
        //             this.offers = [];
        //             this.activeOffer = null;
        //             this.activeCategory = {};
        //             this.offersLimit = offersLimit;
        //             this.mode = mode;
        //             this.categories = {};
        //             this.offersLength = 0;

        //             this.fetchJobs();
        //         }

        //         checkURL() {
        //             const windowURL = new URL(window.location.href);
        //             const params = new URLSearchParams(windowURL.search);
        //             const offerSlug = params.get('offer');

        //             if (offerSlug && offerSlug.length > 0) {
        //                 const offer = this.offers.find((offer) => offer.slug === offerSlug);
        //                 if (offer) this.showOffer(offer.id, offer);
        //             }
        //         }

        //         async fetchJobs() {
        //             try {
        //                 const response = await fetch(`${this.apiURL}/offers/`);
        //                 if (response.status !== 200 || !response.ok) throw new Error('Error');
        //                 const data = await response.json();

        //                 this.offers = data.offers;
        //                 this.offersLength = data.offers.length;
        //                 this.renderOffers(this.offers, 0, this.offersLimit);
        //                 this.checkURL();

        //                 // if (this.mode === 'career') this.createCategories();
        //             } catch (err) {
        //                 this.handleAPIError();
        //                 console.log(err);
        //             }
        //         }

        //         handleAPIError(errorMessage = 'Currently there are no offers') {
        //             const markup = `
        //         <div class="container">
        //             <h4 class="job-board__error">
        //             ${errorMessage}
        //             </h3>
        //         </div>
        //         `;

        //             this.board.innerHTML = markup;
        //             if (this.mode === 'career') this.removeLoadMore();
        //         }

        //         createCategories() {
        //             let allDepartmentCategories = [];
        //             let allLocationCategories = [];
        //             const filterButton = document.querySelector('#filter');

        //             this.offers.forEach((offer) => {
        //                 allDepartmentCategories.push(offer.department);
        //                 allLocationCategories.push(offer.location);
        //             });

        //             this.categories = {
        //                 location: [...new Set(allLocationCategories)],
        //                 department: [...new Set(allDepartmentCategories)],
        //             }

        //             filterButton.addEventListener('click', () => {
        //                 this.createCategoriesModal();
        //             });
        //         }

        //         createCategoriesModal() {
        //             const modal = this.createModalNode(['job-modal'], [{
        //                 name: 'data-modal-offer-id',
        //                 value: 'categories',
        //             }]);

        //             const modalMarkup = `
        //         <div class="job-modal__inner">
        //             <button class="bt-close job-modal__mobile-close"></button>
                
        //             <header class="job-modal__header">
        //             <button class="job-modal__back">
        //                 <div class="bt-arrow job-modal__back-icon"></div>
        //                 <span class="job-modal__back-text">
        //                 Back 
        //                 </span>
        //             </button>

        //             <h2 class="job-modal__header-title">
        //                 Filter by categories
        //             </h2>
        //             </header>

        //             <div class="job-modal__content row">
        //                         ${Object.keys(this.categories)
        //                             .map(
        //                                 categoryKey =>
        //                                     `
        //                                     <div class="job-modal-category col-md-6">
        //                                         <h4 class="job-modal-category__name">
        //                                             ${categoryKey === 'city' ? 'Location' : categoryKey}
        //                                         </h3>

        //                                         <ul class="job-modal-category__list">
        //                                             ${this.categories[categoryKey]
        //                                                 .map(
        //                                                     category =>
        //                                                         `
        //                                                             <li class="job-modal-category__item">
        //                                                                 <button 
        //                                                                     class="job-modal-category__button"
        //                                                                     data-category-name="${categoryKey}"
        //                                                                     data-category-value="${category}"
        //                                                                 >
        //                                                                     ${category}
        //                                                                 </button>	
        //                                                             </li>
        //                                                         `
        //                                                 ).join('')}
        //                                         </ul>
        //                                     </div>
        //                                 `
        //                             ).join('')}
        //                     </div>
        //         </div>
        //         `;

        //             modal.innerHTML = modalMarkup		;
        //             this.openModal(modal);
        //             this.handleModalNativeEvents(modal, '.job-modal__back', '.job-modal__mobile-close');
        //             const categoriesButtons = document.querySelectorAll('.job-modal__inner .job-modal-category__button');
                    
        //             categoriesButtons.forEach((categoryButton) => {
        //                 const { categoryValue, categoryName } = categoryButton.dataset;

        //                 categoryButton.addEventListener('click', () => {
        //                     this.filterCategories(categoryName, categoryValue);
        //                     categoryButton.classList.add('job-modal-category__button--active');
        //                 });

        //                 if (categoryValue === this.getActiveCategory(categoryName)) {
        //                     categoryButton.classList.add('job-modal-category__button--active');
        //                 }
        //             })
        //         }

        //         filterCategories(categoryName, categoryValue) {
        //             let finalOffers = this.offers;

        //             if (this.activeCategory[categoryName] === categoryValue) {
        //                 delete this.activeCategory[categoryName];
        //             } else {
        //                 this.activeCategory[categoryName] = categoryValue;
        //             }
                    
        //             Object.keys(this.activeCategory).forEach((categoryKey) => {
        //                 if (this.activeCategory[categoryKey]) {
        //                     finalOffers = finalOffers.filter((category) => {
        //                         return category[categoryKey] === this.activeCategory[categoryKey];
        //                     });
        //                 }
        //             });

        //             this.board.innerHTML = '';
        //             this.closeOffer();
        //             this.removeLoadMore();
        //             this.renderOffers(finalOffers);
        //         }

        //         renderOffers(offersArray = null, renderStart = false, renderEnd = false) {
        //             const offers = offersArray ? offersArray : this.offers;
        //             const sliceParameters = (renderEnd !== false && renderStart !== false) ? [renderStart, renderEnd] : [false];
        //             const markup = `
        //         ${offers
        //                 .slice(...sliceParameters)
        //                 .map((offer) =>
        //                     `<li
        //                         class="job-board__list__item js-job" 
        //                         data-offer-id="${offer.id}"
        //                     >
        //                         <div class="container">
        //                             <div class="job-board__list__item__container">
        //                                 <div class="job-board__list__item__position">
        //                                     <p>${offer.title}</p>
        //                                 </div>
        //                                 <div class="job-board__list__item__location">
        //                                     <p>${offer.location}</p>
        //                                 </div>
        //                                 <div class="job-board__list__item__arrow">
        //                                     <span></span>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </li>
        //             `).join('')}`;

        //             if (!offers.length) {
        //                 this.handleAPIError('No results...');
        //             }
                    
        //             this.board.innerHTML = this.board.innerHTML + markup;
        //             this.setEventListeners();

        //             setTimeout(function () {
        //                 Site.updateScroll();
        //             }, 100);
        //             // document.querySelector('[data-scroll-container]').locomotive.update();
        //         }

        //         setEventListeners() {
        //             const offers = this.board.querySelectorAll('.js-job');

        //             offers.forEach(offer => {
        //                 offer.addEventListener('click', () => {
        //                     const { offerId } = offer.dataset;
        //                     const offerData = this.getActiveOffer(offerId);
        //                     this.showOffer(offerId, offerData);
        //                 })
        //             })

        //             if (this.mode === 'career' && this.offers.length < this.offersLimit && this.removeLoadMore) {
        //                 this.removeLoadMore();
        //                 return;
        //             }

        //             if (this.mode === 'career') {
        //                 const loadMoreButton = document.querySelector('.js-job-board-more-button');

        //                 if (loadMoreButton) {
        //                     loadMoreButton.addEventListener('click', () => {
        //                         this.renderOffers(null, this.offersLimit, this.offersLength);
        //                         this.removeLoadMore();
        //                     });
        //                 }
        //             }
        //         }

        //         setActiveOffer(offer) {
        //             this.activeOffer = offer;
        //         }

        //         getActiveOffer(offerId) {
        //             return this.offers.find(offer => offer.id == offerId);
        //         }

        //         getActiveCategory(key) {
        //             return this.activeCategory[key] ? this.activeCategory[key] : '';
        //         }

        //         removeLoadMore() {
        //             const loadMoreButton = document.querySelector('.js-job-board-more-button');
        //             if (loadMoreButton) loadMoreButton.remove(loadMoreButton);
        //         }

        //         createModalNode(classes, attributes) {
        //             const modal = document.createElement('div');
        //             modal.classList.add(...classes);

        //             attributes.forEach((attribute) => {
        //                 modal.setAttribute(attribute.name, attribute.value);
        //             });

        //             return modal;
        //         }

        //         handleModalNativeEvents(modalNode, desktopClose, mobileClose) {
        //             modalNode.querySelector(desktopClose).addEventListener('click', () => {
        //                 this.closeOffer();
        //             });

        //             modalNode.querySelector(mobileClose).addEventListener('click', () => {
        //                 this.closeOffer();
        //             });

        //             const escPressHandler = () => {
        //                 if (event.keyCode === 27) {
        //                     this.closeOffer();
        //                     document.removeEventListener('keydown', escPressHandler);
        //                 }
        //             }

        //             document.addEventListener('keydown', escPressHandler);
        //         }

        //         openModal(modalNode) {
        //             document.body.insertBefore(modalNode, document.body.lastChild);

        //             setTimeout(() => {
        //                 modalNode.classList.add('job-modal--is-open');
        //             }, 0);
        //         }

        //         closeModal(modalNode) {
        //             modalNode.classList.remove('job-modal--is-open');

        //             modalNode.addEventListener('transitionend', () => {
        //                 modalNode.remove(modalNode);
        //             });
        //         }

        //         setURLParameter(offer) {
        //             const currentURL = new URL(window.location.href);
        //             currentURL.searchParams.set('offer', offer.slug);

        //             window.history.pushState(null, null, currentURL.toString());
        //         }

        //         deleteURLParameter() {
        //             const currentURL = new URL(window.location.href);
        //             currentURL.searchParams.delete('offer');

        //             window.history.pushState(null, null, currentURL.toString());
        //         }

        //         showOffer(id, offer) {
        //             this.setActiveOffer(offer);
        //             this.setURLParameter(offer);
        //             const modal = this.createModalNode(['job-modal'], [{
        //                 name: 'data-modal-offer-id',
        //                 value: id,
        //             }]);
                    
        //             const modalMarkup = `
        //         <div class="job-modal__inner">
        //             <button class="bt-close job-modal__mobile-close"></button>
                
        //             <header class="job-modal__header">
        //             <button class="job-modal__back">
        //                 <span class="job-modal__back__arrow"></span>
        //                 <span class="job-modal__back__text caption">
        //                 Back to Vay.io
        //                 </span>
        //             </button>

        //             <h2 class="job-modal__header-title">
        //                 ${offer.title}
        //             </h2>

        //             <span class="job-modal__header-location">
        //                 ${offer.location}
        //             </span>
        //             </header>

        //             <div class="job-modal__content">
        //             <h3 class="job-modal__content-heading">
        //                 Job description
        //             </h3>

        //             <div class="job-modal__content-data">
        //                 ${offer.description}
        //             </div>

        //             <h3 class="job-modal__content-heading">
        //                 Requirements
        //             </h3>

        //             <div class="job-modal__content-data">
        //                 ${offer.requirements}
        //             </div>
        //             </div>

        //             <footer class="job-modal__footer">
        //             <a 
        //                 href="${offer.careers_apply_url}" 
        //                 target="_blank"
        //                 class="job-modal__content-button button button--twilight"
        //             >
        //                 Apply for this job
        //             </a>
        //             </footer> 
        //         </div>
        //         `;

        //             modal.innerHTML = modalMarkup;
        //             this.openModal(modal);
        //             this.handleModalNativeEvents(modal, '.job-modal__back', '.job-modal__mobile-close');
        //         }

        //         closeOffer() {
        //             this.setActiveOffer(null);
        //             this.deleteURLParameter();
        //             this.closeModal(document.querySelector('[data-modal-offer-id]'));
        //         }
        //     }

        //     const jobBoardList = document.querySelector('.js-job-board-list');
        //     new JobBoard(jobBoardList, jobBoardList.dataset.jobsVisible, 'career');

        // }
    }

    document.addEventListener('DOMContentLoaded', function () {
        Site.init();
    });

}());
